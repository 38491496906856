// The login page for the sandbox / prd environments is managed by the authentication-loginpage repo
// This is just kept here for local testing the whole application and to have a login place
import { Layout as AntdLayout, Button, Row, Col } from "antd";

import { useEffect } from 'react';

export default function Home() {
    console.log("login component")

    return (
        <AntdLayout
            style={{
                background: `#aab3da`,
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "28px",
                        }}
                    >
                        {/* <img src="./refine.svg" alt="Refine" /> */}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
}
