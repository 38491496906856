import { HttpError } from "@refinedev/core";
import axios, {AxiosRequestConfig} from "axios";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    
);
  
axiosInstance.interceptors.response.use(
    
);

