import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

const Redirect = ( url ) => {
   const navigate = useNavigate();
   const { isAuthenticated } = useAuth0();
   const [searchParams, setSearchParams] = useSearchParams();

   useEffect(() => {
      console.log("Authentication-Login-Page redirect to home")

      if(isAuthenticated){
         console.log("Authentication-Login-Page redirect - user is authenticated")
         window.location.href = process.env.REACT_APP_ENV == "dev" ? "http://dashboard." + process.env.REACT_APP_API_BASE_URL: "https://dashboard." + process.env.REACT_APP_API_BASE_URL;
      }else{
         console.log("Authentication-Login-Page redirect - user is not authenticated")
         console.log(searchParams)
         navigate({
            pathname: '/login/index.html',
            search: `?${createSearchParams(searchParams)}`
         });
      }
   }, [url]);

   return <h5>Redirecting...</h5>;
};

export default Redirect;