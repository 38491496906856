import { Refine,    
    AuthBindings,
    Authenticated, } from "@refinedev/core";
    import {
        notificationProvider,
        RefineThemes,
        ErrorComponent
    } from "@refinedev/antd";
import dataProvider from "../src/utility";
import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet, useMatch  } from "react-router-dom";

import Login from "../src/pages/login/login";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { ConfigProvider } from "antd";
import "@refinedev/antd/dist/reset.css";

import Home from "./pages/home/home";
import Redirect from "./pages/redirect/redirect";

let API_URL = process.env.REACT_APP_API_URL || ""
console.log(process.env.REACT_APP_BUILD_TIMESTAMP)
console.log(process.env.REACT_APP_ENV)
console.log(process.env.REACT_APP_API_URL)
console.log("Authentication-Login-Page")

const App: React.FC = () => {
    const { isLoading, user, logout, getIdTokenClaims } = useAuth0();

    if (isLoading) {
        return <span>loading...</span>;
    }
    

    const authProvider: AuthBindings = {
        login: async () => {
            return {
                success: true,
            };
        },
        logout: async () => {
            logout();
            return {
                success: true,
            };
        },
        onError: async (error) => {
            console.error(error);
            return { error };
        },
        check: async () => {
            try {
                const token = await getIdTokenClaims();
                if (token) {
                    console.log("Authentication-Login-Page User is authenticated")
                    console.log(token)
                    // localStorage.setItem('token', JSON.stringify(token));
                    // axios.defaults.headers.common = {
                    //     Authorization: `Bearer ${token.__raw}`,
                    // };

                    // hard reset to home
                    if (window.location.pathname != "/"){
                        window.location.href = process.env.REACT_APP_ENV == "dev" ? "http://" + process.env.REACT_APP_API_BASE_URL: "https://" + process.env.REACT_APP_API_BASE_URL;
                    }
                    return {
                        authenticated: true,
                        // redirectTo: "/",
                    };

                } else {
                    console.log("Authentication-Login-Page User is not authenticated")
                    return {
                        authenticated: false,
                        error: {
                            message: "Check failed",
                            name: "Token not found",
                        },
                        redirectTo: "/login/index.html",
                        logout: true,
                    };
                }
            } catch (error: any) {
                console.log("Authentication-Login-Page User is not authenticated")
                return {
                    authenticated: false,
                    error: new Error(error),
                    redirectTo: "/login/index.html",
                    logout: true,
                };
            }
        },
        getPermissions: async () => null,
        getIdentity: async () => {            
            if (user) {
                console.log(user)
                return {
                    ...user,
                    avatar: user.picture,
                };
            }
            return null;
        },
    };

    return (
        <BrowserRouter>
            <ConfigProvider theme={RefineThemes.Blue}>
            <Refine
                    authProvider={authProvider}
                    dataProvider={dataProvider(API_URL)}
                    routerProvider={routerProvider}
                    resources={[
                        {
                            name: "login",
                            list: "/login"
                        }
                    ]}
                    notificationProvider={notificationProvider}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: true,
                    }}
                >
                    <Routes>
                        <Route
                                element={
                                    <Authenticated
                                        fallback={<CatchAllNavigate to="/login/index.html" />}
                                    >
                                    </Authenticated>
                                }
                        >
                                <Route path="/" element={<Home />} />
                                <Route path="/redirect" element={<Redirect/>} />
                        </Route>
                        <Route
                                element={
                                    <Authenticated fallback={<Outlet />}>
                                        <NavigateToResource resource="" />
                                    </Authenticated>
                                }
                        >
                                
                                <Route path="/login/index.html" element={<Login />} />
                                <Route path="*" element={<Redirect/>} />
                        </Route>
                        <Route path="*" element={<Login/>} />
                    </Routes>
                </Refine>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
